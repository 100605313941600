import React, {Component} from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick';
import style from './alert.module.scss';
import parse from 'html-react-parser';
// Import neccessary site components, try to maintain alphabetical order
import Icon from 'components/icon';
import Link from 'shared/link';

class Alert extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentSlide: 1,
			height: 0,
		};
		this.leftArrowClick = this.leftArrowClick.bind(this);
		this.rightArrowClick = this.rightArrowClick.bind(this);
		this.clickHandler = this.clickHandler.bind(this);
	}

	componentDidMount() {
		if (document.getElementById('messageContainer')) {
			var height = document.getElementById('messageContainer').clientHeight;
			if(sessionStorage.getItem('hma_alert')) {
				height = parseInt(sessionStorage.getItem('hma_alert'));
				this.props.alertoff();
			} else {
				this.props.alerton();
			}
			this.setState({ height: height });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data !== prevProps.data) {
			if (document.getElementById('messageContainer')) {
				var height = document.getElementById('messageContainer').clientHeight;
				if(sessionStorage.getItem('hma_alert')) {
					height = parseInt(sessionStorage.getItem('hma_alert'));
					this.props.alertoff();
				} else {
					this.props.alerton();
				}
				this.setState({ height: height });
			}
		}
	}

	clickHandler() {
		this.setState({height: 0});
		this.props.alertoff();
		sessionStorage.setItem('hma_alert', 0);
	}

	leftArrowClick() {
		this.slider.slickPrev();
	}

	rightArrowClick() {
		this.slider.slickNext();
	}

	render() {
		const settings = {
			dots: false,
			arrows: false,
			autoPlay: false,
			ref: slider => (this.slider = slider),
			beforeChange: (current, next) => this.setState({currentSlide: next+1}),
		}
		if (!this.props.data.length) {
			return null;
		} else {
			return (
				<div className={style.alert} style={{'height':this.state.height}}>
					<div id="messageContainer" className={[style.innerContainer, "grid-container"].join(' ')}>
						<div className="grid-x grid-margin-x">
							<div className={`cell small-12 medium-${this.props.data.length > 1 ? '10' : '12'}`}>
								<Slider {...settings}>
									{this.props.data.map((alert, index) => (
										<div key={index} className={style.banner}>
											<Icon icon={alert.icon} foreground="#979797" className={style.icon} />
											<div className={style.title}><span className={style.emphasis}>{alert.attention}:</span> {parse(alert.message)}</div>
										</div>
									))}
								</Slider>
							</div>
							{this.props.data.length > 1 && <div className="cell small-5 medium-2">
								<div className={style.pagination}>
									<div onClick={this.leftArrowClick} className={style.leftArrow}>&lt;</div>
									<div className={style.count}>{this.state.currentSlide} of {this.props.data.length}</div>
									<div onClick={this.rightArrowClick} className={style.rightArrow}>&gt;</div>
								</div>
							</div>}
							<div role="presentation" onClick={this.clickHandler} className={style.close}>
								<Icon icon='close' />
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

const mapStateToProps = (state, props) => {
  return {
    alert: state.alert
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    alerton: () => dispatch({type:'ALERTMODE_ON'}),
    alertoff: () => dispatch({type: 'ALERTMODE_OFF'}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);