import React, {Component} from 'react'
import style from './navigation.module.scss';

// Import neccessary site components, try to maintain alphabetical order
import Link from 'shared/link';

class Navigation extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<nav className={style.main}>
				<ul className={style.container}>
					<li><Link className={style.link} href="/providers/">Find A Provider</Link></li>
					<li><Link className={style.link} href="/locations/">Find A Location</Link></li>
					<li><Link className={style.link} href="/specialties/">Specialties & Services</Link></li>
				</ul>
			</nav>
		)
	}
}

export default Navigation;