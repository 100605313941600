import React, {Component} from 'react'
import style from './utility.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faUserCircle } from '@fortawesome/free-regular-svg-icons'

// Import neccessary site components, try to maintain alphabetical order
import Icon from 'components/icon';
import Link from 'shared/link';

class Utility extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<nav className={style.utility}>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12">
								<ul className={style.container}>
									<li><Link className={style.link} href="/telehealth-visits/"><Icon className={ [style.icon, style.virtualVisit].join(' ') } icon="visit" title="Virtual Visit Icon" />Telehealth Visits</Link></li>
									<li><Link className={style.link} href="/request-appointment/"><FontAwesomeIcon className={style.icon} icon={faCalendarCheck} title="Request Appointment Icon" />Request Appointment</Link></li>
									<li><Link className={style.link} href="/patient-portal/"><FontAwesomeIcon className={style.icon} icon={faUserCircle} title="Pay My Doctor Icon" />FollowMyHealth</Link></li>
								</ul>
							</div>
						</div>
					</div>
				</nav>
			</>
		)
	}
}

export default Utility;