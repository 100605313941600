import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Location } from '@reach/router';
import { Link } from 'gatsby';
import { connect } from 'react-redux'

class ZLink extends Component {
	constructor(props) {
		super(props);
		this.category = 'Internal Link';
		this.customOnClick = null;
		this.buttonClick = this.buttonClick.bind(this);
	}
	static propTypes = {
		location: PropTypes.object.isRequired
	}
	buttonClick(e) {
		if (this.customOnClick) {
			let result = this.customOnClick(e);
			if (result === false || e.defaultPrevented) {
				e.preventDefault();
				return;
			}
		}
		window.dataLayer = window.dataLayer || [];
		let linkLocation = e.currentTarget.parentNode.getAttribute("datalayer");
		var event = {};
		event.action = e.type;
		let href = this.props.to||this.props.href;
		event.link = href;
		event.page = this.props.location.pathname;
		event.category = this.category;
		if(typeof this.props.children === 'string') {
			event.text = this.props.children;
		}
		event.location = linkLocation;
		window.dataLayer.push(event);
		if (this.props.reset === true && this.props.resetOnNavigate !== undefined) {
			this.props.resetOnNavigate();
		}
	}
	render() {
		const scheme = /(?:https?:)?\/\/([^/]+)/i;
		const { location } = this.props;
		const trailingSlash = ('trailingSlash' in this.props ? this.props.trailingSlash : true);
		let href = this.props.to||this.props.href;
		let passthrough = Object.assign({}, this.props);
		this.customOnClick = passthrough.onClick;
		delete passthrough.location;
		delete passthrough.to;
		delete passthrough.href;
		delete passthrough.resetOnNavigate;
		delete passthrough.reset;
		delete passthrough.onClick;
		let result;
		let external = ('external' in this.props ? this.props.external : false);
		if (!('external' in this.props) && (result = scheme.exec(href)) !== null && result[1] !== location.host) {
			external = true;
			this.category = 'Outbound Link';
		}
		const className = this.props.className||'';
		if (external === false) {
			if (trailingSlash && !/\/$/.test(href)) {
				href += '/';
			}
			return (
				<Link onClick={this.buttonClick} className={className} to={ href } {...passthrough}>{this.props.children}</Link>
			)
		}
		return (
			<a onClick={this.buttonClick} className={className} href={ href } target="_blank" rel="noopener noreferrer" {...passthrough}>{this.props.children}</a>
		)
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		resetOnNavigate: () => dispatch({
			type: 'RESET',
		}),
	};
};

export default connect(null, mapDispatchToProps)(props => {
	return (
		<Location>
			{locationProps => <ZLink location={locationProps.location} {...props}>{props.children}</ZLink>}
		</Location>
	)
});

/*
export default props => (
	<Location>
		{locationProps => <ZLink location={locationProps.location} {...props}>{props.children}</ZLink>}
	</Location>
);
*/