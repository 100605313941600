import React, {Component} from 'react'
import style from './offcanvas.module.scss';
import $ from 'jquery';
// Import neccessary site components, try to maintain alphabetical order
import Icon from 'components/icon';
import Link from 'shared/link';
import parse from 'html-react-parser';

class Offcanvas extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.menu = {
			menuID: "main",
			pages: [
				{
					href: '/patient-info/',
					target: '',
					text: 'Patient Info',
					visible: 'true',
					children: [
						{
							href: '/patient-info/insurance-info/',
							target: '',
							text: 'Insurance Info',
							visible: 'true',
						},
						{
							href: '/patient-info/covid-19/',
							target: '',
							text: 'Covid-19',
							visible: 'true',
						},
						{
							href: '/symptoms-conditions-guide/',
							target: '',
							text: 'Symptoms & Conditions Guide',
							visible: 'true',
						},
						{
							href: '/patient-info/rights-responsibilities/',
							target: '',
							text: 'Patient Rights and Responsibilites',
							visible: 'true',
						},
						{
							href: '/patient-info/medical-records-release/',
							target: '',
							text: 'Medical Records Release',
							visible: 'true',
						},
						{
							href: '/patient-info/covid-19-visitor-guidelines/',
							target: '',
							text: 'Visitor Guidelines',
							visible: 'true',
						}
					]
				},
				{
					href: '/topic/patient-education/',
					target: '',
					text: 'Patient Education',
					visible: 'true',
				},
				{
					href: '/about/',
					target: '',
					text: 'About',
					visible: 'true',
					children: [
						{
							href: '/about/leadership/',
							target: '',
							text: 'Leadership',
							visible: 'true',
						},
						{
							href: '/topic/news/',
							target: '',
							text: 'Newsroom',
							visible: 'true',
						}
					]
				},
				{
					href: '/careers/',
					target: '',
					text: 'Careers',
					visible: 'true',
				},
				{
					href: '/about/faqs/',
					target: '',
					text: 'FAQs',
					visible: 'true',
				}
			]
		}
	}

	handleClick(evt) {
		this.props.offcanvasClose();
		if (this.props.onNavigationChange) {
			evt.preventDefault();
			const path = $(evt.target).attr('href');
			window.__executeScrollAction(path.replace(/\//g, ''));
			this.props.onNavigationChange.apply(this, [{path: path}]);
		}
	}

	handleDrilldown({ target }) {
		const parent = target.parentNode;
		const siblings = parent.childNodes;
		if (parent.classList.contains("js-drilldown-back")) {
			const activeMenus = document.querySelectorAll('.menu.vertical.nested.is-active');
			let currentMenu = activeMenus[activeMenus.length-1];
			currentMenu.classList.remove('is-active');
			currentMenu.classList.add('invisible');
		} else {
			siblings.forEach(element => {
				if (element.nodeName === "UL") {
					element.classList.remove('invisible');
					element.classList.add('is-active');
				}
			});
		}
	}

	render() {
		const position = this.props.position||'right';
		let classes = [style.wrapper];
		if (this.props.isOpen)classes.push(style.isOpen);

		let state = {
			disableScrollUpdate: true,
			scrollIntoPosition: true,
			historyOnly: true,
		};

		const renderMenu = (pages, parent) => {
			return pages.map(({ children, href, text }, index) => {
				return (
					<>
						{ index === 0 &&
							<>
								<li key={`offcanvas-subnav-back-${index}`} className={`js-drilldown-back`}><div className={[style.link, style.back].join(' ')} onClick={this.handleDrilldown.bind(this)} key={`offcanvas-subnav-back`}>Back</div></li>
								<li key={`offcanvas-parent-${index}`}><Link to={parent.href} className={style.link}>{ parse(parent.text) }</Link></li>
							</>
						}
						{ children ?
							<li key={`offcanvas-subnav-parent-${index}`} className={`is-drilldown-submenu-parent`}>
								<div onClick={this.handleDrilldown.bind(this)} className={[style.link, style.parent].join(' ')}>{ parse(text) }</div>
								{ <ul className={`menu vertical nested submenu is-drilldown-submenu drilldown-submenu-cover-previous invisible`}>
									{ renderMenu(children, { href, text }) }
								</ul> }
							</li>
							:
							<li key={`offcanvas-subnav-${index}`}><Link to={href} className={style.link}>{ parse(text) }</Link></li>
						}
					</>
				);
			});
		};

		return (
			<div className={ classes.join(' ') }>
				<div className={ [style.menu, style[position]].join(' ') }>
					<button aria-label="Close off canvas navigation" className={ style.close } onClick={this.props.offcanvasClose} ><Icon icon="close" foreground="#ffffff" width="32" height="32"/></button>
					<div className="grid-container">
						<div className="grid-x">
							<div className={[style.bigLinks, "cell small-12"].join(' ')}>
								<Link className={style.link} href="/providers/">Find A Provider</Link>
								<Link className={style.link} href="/locations/">Find A Location</Link>
								<Link className={style.link} href="/specialties/">Specialties & Services</Link>
							</div>
						</div>
						<div className="grid-x">
							<div className={[style.smallLinks, `cell small-12 is-drilldown`].join(' ')}>
								<ul className={`vertical menu drilldown`}>
									{ this.menu && this.menu.pages && this.menu.pages.map(({ visible, text, href, children }, i) => {
										if (children && visible) {
											return (
												<li key={`offcanvas-main-nav-parent-${i}`} className={`is-drilldown-submenu-parent`}>
													<div onClick={this.handleDrilldown.bind(this)} key={`offcanvas-subnav-parent-link-${i}`} className={[style.link, style.parent].join(' ')}>{ parse(text) }</div>
													{ <ul className={`menu vertical nested submenu is-drilldown-submenu drilldown-submenu-cover-previous invisible`}>
														{ renderMenu(children, { href, text }) }
													</ul> }
												</li>
											)
										}
										return visible ? <li key={`offcanvas-main-nav-child-${i}`}><Link className={style.link} reset="true" to={href}>{parse(text)}</Link></li> : ""
									})}
								</ul>
							</div>
						</div>
						<div className="grid-x">
							<div className={[style.utilityLinks, "cell small-12"].join(' ')}>
								<Link className={style.link} href="/telehealth-visits/">Telehealth Visits</Link>
								<Link className={style.link} href="/request-appointment/">Request Appointment</Link>
								<Link className={style.link} href="/patient-portal/">FollowMyHealth</Link>
							</div>
						</div>
					</div>
				</div>
				<div className={[style.content, style[position]].join(' ')}>
					{this.props.children}
				</div>
			</div>
		)
	}
}

export default Offcanvas;