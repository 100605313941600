import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby';
// Import neccessary site components, try to maintain alphabetical order
import Alert from 'components/alert/';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

class AlertController extends Component {

	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}alerts/alert/all/?_join=true&column=sort`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					this.setState({
						status: STATUS.LOADED,
						data: data,
					})
				}
			})
			.catch(err => console.log);
	}

	constructor(props) {
		super(props);

		let initialData = [];
		if (props.data && props.data.allAlertsAlert && props.data.allAlertsAlert.edges.length) {
			props.data.allAlertsAlert.edges.forEach(alert => {
				initialData.push(alert.node);
			});
		}
		this.state = {
			status: STATUS.INITIAL,
			data: initialData,
		}
		this.liveRefresh = this.liveRefresh.bind(this);
	}

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		return (
			<Alert data={this.state.data}/>
		);
	}
}

export default (props) => (
	<AlertController {...props} />
)