import React, {Component} from 'react'
import style from './header.module.scss';

// Import neccessary site components, try to maintain alphabetical order
import AlertController from 'controllers/alert/alert';
import Icon from 'components/icon';
import Link from 'shared/link';
import Navigation from 'components/navigation/';
import Utility from 'components/navigation/utility';

class Header extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<Utility />
				<div className={style.container}>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-8 medium-3">
								<Link href="/"><img src="/logo_final.svg" alt="Heritage Medical Associates" /></Link>
							</div>
							<div className="cell small-4 medium-offset-1 medium-8">
								<div className={style.navigationContainer}>
									<Navigation />
									<div className={ [style.buttonContainer, this.props.buttonContainerClass].filter((item) => item).join(' ') }>
										<button className={ style.offcanvasToggle } onClick={this.props.offcanvasOpen} aria-label="Offcanvas navigation menu" aria-controls="offcanvasMenu" aria-expanded={this.props.offcanvasState}><Icon icon="bars" width="20" height="20"/></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<AlertController />
			</>
		)
	}
}

export default Header;